import { graphql } from "gatsby";
import React from "react";
import { StringParam, useQueryParam } from "use-query-params";
import Results from "../components/results";
import { normalizeArticle } from "../helper/normalizer";
import Breadcrumb from "../modules/breadcrumb";
import Layout from "../modules/layout";
import SEO from "../modules/seo";

const ArticleOverview = ({
  data: {
    articles,
    allContentfulFocusOfWork,
    allContentfulOrganization,
    allContentfulTopic,
    allContentfulRegion,
    allContentfulArticleType,
  },
  pageContext,
}) => {
  const { microcopy, node_locale } = pageContext.settings;
  const siteSettings = pageContext.settings;
  const { breadcrumbs, showBreadcrumbNavigation } = pageContext;

  const topics = allContentfulTopic.edges.map((edge) => edge.node);
  const focusesOfWork = allContentfulFocusOfWork.edges.map((edge) => edge.node);
  const organizations = allContentfulOrganization.edges.map(
    (edge) => edge.node
  );
  const regions = allContentfulRegion.edges.map((edge) => edge.node);
  const articleTypes = allContentfulArticleType.edges.map((edge) => edge.node);

  const typeQueryParam = useQueryParam("type", StringParam)[0];
  const typeMatchedFilter =
    typeQueryParam &&
    articleTypes.find(
      (type) => type.contentful_id === typeQueryParam.toLowerCase()
    );

  const items = articles.edges.map((edge) => ({
    ...normalizeArticle(edge.node.articleDetail),
    generalTitle: edge.node.title,
  }));

  return (
    <Layout siteSettings={siteSettings}>
      <SEO
        title={`${siteSettings.siteName} - ${pageContext.name}`}
        description={siteSettings.siteDescription.siteDescription}
        author={siteSettings.siteAuthor}
      />
      <Breadcrumb
        visible={showBreadcrumbNavigation}
        breadcrumbs={breadcrumbs}
      ></Breadcrumb>
      <div className="articleOverview">
        <Results
          type={typeMatchedFilter ? typeQueryParam : "artikel"}
          articleTypes={articleTypes}
          topics={topics}
          focusesOfWork={focusesOfWork}
          organizations={organizations}
          regions={regions}
          items={items}
          microcopy={microcopy}
          locale={node_locale}
        />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($node_locale: String!) {
    articles: allContentfulArticleGeneral(
      filter: { articleDetail: { node_locale: { eq: $node_locale } } }
    ) {
      totalCount
      edges {
        node {
          title
          articleDetail {
            node_locale
            imageLandscape {
              imageLandscape {
                fluid {
                  srcWebp
                  srcSetWebp
                  srcSet
                  src
                  aspectRatio
                }
              }
            }
            articleReader {
              json
              content {
                content {
                  value
                }
              }
            }
            articleContent {
              json
            }
            articleTitle
            articleSubtitle
            visibleTags
            organization {
              id
              name
            }
            topic {
              contentful_id
              id
              name
            }
            focusOfWork {
              contentful_id
              id
              name
            }
            region {
              contentful_id
              id
              name
            }
            postDateTime(formatString: "DD MMMM YYYY")
            authorOfPublication {
              id
              name
            }
            typeOfArticle {
              id
              name
            }
          }
        }
      }
    }
    allContentfulFocusOfWork(filter: { node_locale: { eq: $node_locale } }) {
      edges {
        node {
          id
          contentful_id
          name
        }
      }
    }
    allContentfulArticleType(filter: { node_locale: { eq: $node_locale } }) {
      edges {
        node {
          id
          contentful_id
          name
        }
      }
    }
    allContentfulOrganization(filter: { node_locale: { eq: $node_locale } }) {
      edges {
        node {
          id
          contentful_id
          name
        }
      }
    }
    allContentfulTopic(filter: { node_locale: { eq: $node_locale } }) {
      edges {
        node {
          id
          contentful_id
          name
        }
      }
    }
    allContentfulRegion(filter: { node_locale: { eq: $node_locale } }) {
      edges {
        node {
          id
          contentful_id
          name
        }
      }
    }
  }
`;

export default ArticleOverview;
